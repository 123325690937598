import { combineReducers } from 'redux';
import {
  LOAD_SIMILAR_DATA,
  LOAD_SIMILAR_DATA_SUCCESS,
  LOAD_SIMILAR_DATA_ERROR,
  FILTER_PER_PAGE,
  FILTER_ORDER_BY,
  CHANGE_PAGE,
} from './constants';
import {
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
} from '~/components/Gallery/constants';

export const initialState = {
  collection: [],
  data: {},
  hasNext: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
  dataType: '',
};

export default function similarReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_SIMILAR_DATA:
      return {
        ...state,
        query: {
          data_id: action.payload.data_id,
          slug: action.payload.slug || null,
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
          order_by: action.payload.order_by || 'popular',
          service_type_for_similar:
            action.payload.service_type_for_similar || '',
          same_creator: action.payload.same_creator || false,
        },
        loading: true,
        error: false,
      };
    case LOAD_SIMILAR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: action.collection,
        data: action?.data || null,
        hasNext: action.has_next,
        photoSimilar: action.photo_similar || null,
        silhouetteSimilar: action.silhouette_similar || null,
        page: action.page,
        totalPage: action.total_page,
        totalItem: action.total_item,
        dataType: action.dataType || '',
      };
    case LOAD_SIMILAR_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case NICE_PHOTO_SUCCESS:
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

// const filterInitState = {
//     orderBy: 'popular',
//     download: 'desc',
//     createdAt: '',
//     perPage: '100'
// };

// function filterReducer(state = filterInitState, action) {
//     switch (action.type) {
//         case FILTER_ORDER_BY:
//             return {
//                 ...state,
//                 orderBy: action.value,
//                 download: action.value === 'popular' ? 'desc' : '',
//                 createdAt: action.value === 'newest' ? 'desc' : ''
//             };
//         case FILTER_PER_PAGE:
//             return {
//                 ...state,
//                 perPage: action.value
//             };
//         default:
//             return state;
//     }
// }
//
// export default combineReducers({
//     result: similarReducers,
//     filter: filterReducer
// });
