import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import isString from 'lodash/isString';
import { i18n } from 'next-i18next';
import {
  LOAD_PHOTO,
  LOAD_PHOTO_SUCCESS,
  LOAD_PHOTO_ERROR,
  DOWNLOAD_PHOTO,
  DOWNLOAD_PHOTO_ERROR,
  DOWNLOAD_PHOTO_SUCCESS,
  NICE_PHOTO,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
  SHOW_GOI_IT,
  HIDE_GOI_IT,
  LOAD_STATISTICS,
  LOAD_STATISTICS_ERROR,
  LOAD_STATISTICS_SUCCESS,
  LOAD_SIMILAR_LIST,
  LOAD_SIMILAR_LIST_SUCCESS,
  LOAD_SIMILAR_LIST_ERROR,
  LOAD_SIMILAR_DESIGN,
  LOAD_SIMILAR_DESIGN_SUCCESS,
  LOAD_SIMILAR_DESIGN_ERROR,
  DOWNLOAD_PHOTO_IN_PROGRESS,
  GET_CURRENT_STATUS_DOWNLOAD_PHOTO,
  LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS,
  LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR,
  OPEN_EDITOR,
  OPEN_EDITOR_DONE,
  LOG_CLICK_PHOTO,
  LOAD_RELATED_DATA,
  LOAD_RELATED_DATA_SUCCESS,
  LOAD_RELATED_DATA_ERROR,
  LOAD_CURRENT_USER_EXTRA_LICENSE,
  LOAD_CARD_INFO,
  LOAD_CARD_INFO_SUCCESS,
  CHECK_BOOKMARK_DATA,
  UPDATE_BOOKMARK_DATA,
  SAVE_BOOKMARK_DATA,
} from './constants';
import { makeSelectPhoto } from './selectors';
import * as photoPageService from '~/services/photo-page';
import * as logBehaviorService from '~/services/logBehaviorService';
import Cookies from '~/services/Cookies';
import { showError } from '~/containers/CustomErrorMessage/actions';
import { makeSelectProfile } from '~/containers/AuthProvider/selectors';
import Config from '~/config';
import { updatePhoto } from '~/containers/PhotoPage/actions';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import * as subscriptionHistoryService from '~/services/my-page/subscription-service';

// import fetch from 'node-fetch';

function* loadPhoto(action) {
  const { payload: params, api } = action;
  if (!params.gotIt) {
    yield put({ type: SHOW_GOI_IT });
  }

  try {
    const apiFunction = api
      ? api.data.getDetailData
      : photoPageService.getDetailPhoto;
    const photo = yield call(apiFunction, params);
    if (photo.assets && !photo.assets.find((item) => item.active)) {
      photo.assets[0].active = true;
    }

    if (isString(photo.path && photo.thumbnail)) {
      photo.photoPreview = photo.images || [];
    }

    yield put({
      type: LOAD_PHOTO_SUCCESS,
      photo,
      ...photo,
    });
    return photo;
  } catch (error) {
    console.log('error', error);
    yield put({
      type: LOAD_PHOTO_ERROR,
      error: true,
    });
  }

  // async function coverImageToBase64(src) {
  //   const res = await fetch(src);
  //   const contentType = res.headers.get('content-type');
  //   const buffer = await res.buffer();
  //   return `data:${contentType};base64,${buffer.toString('base64')}`;
  // }
}

function* loadCurrentUserExtraLicense(params) {
  try {
    const dataExtra = yield call(
      photoPageService.getExtraLicensePhoto,
      params.payload,
    );

    yield put(
      updatePhoto({
        current_user_extra_license: dataExtra.data,
      }),
    );
  } catch (error) {
    yield put(
      updatePhoto({
        current_user_extra_license: null,
      }),
    );
  }
}

function* loadCardInfo() {
  try {
    const apiFunction = subscriptionHistoryService.getCardInformation;

    const card = yield call(apiFunction, {});
    yield put({
      type: LOAD_CARD_INFO_SUCCESS,
      card,
    });
  } catch (error) {
    yield put({
      type: LOAD_CARD_INFO_SUCCESS,
      card: null,
    });
  }
}

function* downloadPhoto(data) {
  try {
    const response = yield call(photoPageService.downloadPhoto, data.params);
    yield put({ type: DOWNLOAD_PHOTO_IN_PROGRESS });
    // if (data.params.openEditor) {
    //   // yield call(openEditor, {
    //   //   params: data.params.openEditor.params,
    //   //   // newWindow: window.open(data.params.openEditor.newWindow, '_blank'),
    //   // });
    // }

    yield call(
      photoPageService.saveImageWithUrl,
      response.link,
      response.filename,
      data.params.type,
    );

    yield put({
      type: DOWNLOAD_PHOTO_SUCCESS,
      response,
    });

    if (data.params.userId) {
      try {
        const response = yield call(
          photoPageService.getCurrentStatusDownloadFromRedis,
          data.params.userId,
        );
        yield put({
          type: LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS,
          currentDownloadStatus: {
            isForcedLogout: response.isForcedLogout,
            isShowedCaptchar: response.isShowedCaptchar,
          },
        });
      } catch (error) {
        yield put({
          type: LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR,
          error: true,
        });
      }
    }
    if (
      Config.serviceType === 'photo_ac'
      // || Config.serviceType === 'illust_ac'
    ) {
      yield call(logBehaviorService.logBehavior, {
        userId: data.params.userIdforLog,
        dataId: data.params.id,
        action: 'buy',
      });
    }
  } catch (error) {
    yield put(
      showError({
        error,
        title: i18n.t(Config.getPrefixContent('cannot_download')),
        errorType: 'DOWNLOAD_ERROR',
      }),
    );
    yield put({
      type: DOWNLOAD_PHOTO_ERROR,
      error: true,
    });
  }
}

function* getCurrentStatusDownloadFromRedis(action) {
  try {
    const response = yield call(
      photoPageService.getCurrentStatusDownloadFromRedis,
      action.userId,
    );
    yield put({
      type: LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS,
      currentDownloadStatus: {
        isForcedLogout: response.isForcedLogout,
        isShowedCaptchar: response.isShowedCaptchar,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({ type: LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR, error: true });
  }
}

function* loadSimilarDesignAc(params) {
  if (!params.gotIt) {
    yield put({ type: SHOW_GOI_IT });
  }

  try {
    const photo = yield call(photoPageService.getDetailPhoto, {
      id: params.id,
      language: params.lng,
    });
    const categori = photo.categories[0];

    const {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      category,
    } = yield call(photoPageService.getDataOfCategory, categori, params.lng);

    const pageInt = parseInt(page);

    yield put({
      type: LOAD_SIMILAR_DESIGN_SUCCESS,
      category,
      collection,
      has_next,
      page: pageInt,
      total_page,
      total_item,
    });
  } catch (error) {
    yield put({ type: LOAD_SIMILAR_DESIGN_ERROR, error: true || {} });
  }
}

function* loadSimilar(params) {
  console.log('Vao similar loadSimilar');
  try {
    let {
      collection,
      has_next,
      page,
      data,
      photo_similar,
      silhouette_similar,
      total_page,
      total_item,
      illust_similar,
      design_similar,
    } = yield call(photoPageService.getSimilarData, params.query, params.lng);

    const mapThumbnail = (dataField, type = null) => {
      if (data?.[dataField] && data[dataField].length > 0) {
        data[dataField] = data[dataField].map((item) => {
          if (item?.filename) {
            item.thumbnail = Config.renderThumbnailImageByFileName(
              item.filename,
              type || dataField,
            );
          }
          return item;
        });
      }
    };

    mapThumbnail('collection');
    mapThumbnail('photo_similar', 'photo_ac');
    mapThumbnail('silhouette_similar', 'silhouette_ac');
    mapThumbnail('illust_similar', 'illust_ac');
    mapThumbnail('design_similar', 'design_ac');

    yield put({
      type: LOAD_SIMILAR_LIST_SUCCESS,
      collection,
      data,
      has_next,
      page: parseInt(page, 10),
      photo_similar,
      silhouette_similar,
      illust_similar,
      design_similar,
      total_page,
      total_item,
    });
    console.log(
      'collection truoc 22222',
      data,
      collection,
      photo_similar,
      silhouette_similar,
      illust_similar,
      design_similar,
    );
  } catch (error) {
    yield put({
      type: LOAD_SIMILAR_LIST_ERROR,
      error: (error.response && error.response.data) || {},
    });
  }
}

function* nicePhoto(params) {
  console.log('---> nice photo <---');
  console.log(params);
  const photo = yield select(makeSelectPhoto());
  const profile = yield select(makeSelectProfile());
  try {
    if (photo.is_nice) {
      // return yield put({
      //   type: OPEN_MODAL,
      //   title: i18n.t(Config.getPrefixContent('already_nice')),
      // });
      console.log('HIEN TAI HINH DANG CO LIKE SAN');
      yield put({
        type: REMOVE_NICE_PHOTO_SUCCESS,
      });
      yield call(photoPageService.removeNicePhoto, params.id);
    } else {
      console.log('HIEN TAI HINH CHUA DUOC LIKE NEN SE LIKE');

      yield put({
        type: NICE_PHOTO_SUCCESS,
      });
      yield call(photoPageService.nicePhoto, params.id);
      if (
        Config.serviceType === 'photo_ac'
        // || Config.serviceType === 'illust_ac'
      ) {
        yield call(logBehaviorService.logBehavior, {
          userId: profile.user_id,
          dataId: params.id,
          action: 'like',
        });
      }
    }
  } catch (error) {
    // yield put({
    //   type: OPEN_MODAL,
    //   title: i18n.t(Config.getPrefixContent('already_nice')),
    // });
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

function* hideGotIt() {
  yield Cookies.set('gotIt', false);
}

function* loadStatitics(params) {
  try {
    const response = yield call(photoPageService.getStatitics, params.id);

    yield put({
      type: LOAD_STATISTICS_SUCCESS,
      latsDownload: response.latsDownload,
      peopleViewing: response.peopleViewing,
    });
  } catch (error) {
    yield put({ type: LOAD_STATISTICS_ERROR, error: true });
  }
}

function fakeClickLink(dataUrl) {
  const anchorEl = document.createElement('a');
  anchorEl.href = dataUrl;
  anchorEl.target = '_blank';
  document.body.appendChild(anchorEl);
  anchorEl.click();
  anchorEl.remove();
}

function* openEditor(action) {
  try {
    const response = yield call(
      photoPageService.generateTokenForEditor,
      action.params,
    );
    let lng = i18n.language;
    const editorACLanguages = ['en', 'vi', 'tw', 'ko'];
    if (!editorACLanguages.includes(lng)) {
      lng = 'en';
    }
    const editorURL = `${Config.domainService.editorAC}/${lng}/photo?token=${response.token}&is_from=gl&lang=${lng}`;
    // const editorWindow = action.newWindow;
    // if (editorWindow) {
    //   editorWindow.location.href = editorURL;
    //   editorWindow.focus();
    // } else {
    yield call(fakeClickLink, editorURL);
    // }
  } catch (error) {
    yield put(showError({ error }));
  } finally {
    yield put({ type: OPEN_EDITOR_DONE });
  }
}

function* logClick(action) {
  try {
    yield call(logBehaviorService.logBehavior, {
      userId: action.params.userId,
      dataId: action.params.dataId,
      action: 'click',
    });
  } catch (error) {
    console.log('--- check logClick error ---');
    console.log(error);
    console.log('--- end logClick error ---');
  }
}

function* loadRelatedData(action) {
  try {
    const { collection } = yield call(
      photoPageService.getRelatedData,
      action.payload.dataID,
    );
    console.log('loadRelatedDataaaa', collection.length);

    yield put({
      type: LOAD_RELATED_DATA_SUCCESS,
      payload: collection,
    });
  } catch (error) {
    yield put({
      type: LOAD_RELATED_DATA_ERROR,
      error: (error.response && error.response.data) || {},
    });
  }
}

function* checkBookmark({ params }) {
  try {
    const { bookmarkData } = yield call(photoPageService.checkBookmark, params);

    if (bookmarkData?.id) {
      yield put({
        type: UPDATE_BOOKMARK_DATA,
        payload: true,
      });
    } else {
      yield put({
        type: UPDATE_BOOKMARK_DATA,
        payload: false,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_BOOKMARK_DATA,
      payload: false,
    });
  }
}

function* saveBookmark({ payload }) {
  try {
    const { isSaveBookmark } = yield call(
      photoPageService.saveBookmark,
      payload,
    );
    yield put({
      type: UPDATE_BOOKMARK_DATA,
      payload: isSaveBookmark,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: UPDATE_BOOKMARK_DATA,
      payload: false,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* photoSaga() {
  yield takeLatest(LOAD_PHOTO, loadPhoto);
  yield takeLatest(DOWNLOAD_PHOTO, downloadPhoto);
  yield takeLatest(NICE_PHOTO, nicePhoto);
  yield takeLatest(HIDE_GOI_IT, hideGotIt);
  yield takeLatest(LOAD_STATISTICS, loadStatitics);
  yield takeLatest(LOAD_SIMILAR_LIST, loadSimilar);
  yield takeLatest(LOAD_SIMILAR_DESIGN, loadSimilarDesignAc);
  yield takeLatest(
    LOAD_CURRENT_USER_EXTRA_LICENSE,
    loadCurrentUserExtraLicense,
  );
  yield takeLatest(LOAD_CARD_INFO, loadCardInfo);
  // yield takeLatest(
  //   GET_CURRENT_STATUS_DOWNLOAD_PHOTO,
  //   getCurrentStatusDownloadFromRedis,
  // );
  yield takeLatest(OPEN_EDITOR, openEditor);
  yield takeLatest(LOG_CLICK_PHOTO, logClick);
  yield takeLatest(LOAD_RELATED_DATA, loadRelatedData);
  yield takeLatest(CHECK_BOOKMARK_DATA, checkBookmark);
  yield takeLatest(SAVE_BOOKMARK_DATA, saveBookmark);
}
